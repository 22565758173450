<template>
    <div class="passwordwrap">
        <div class="row">
            <div class="lable">原密码</div>
            <div class="content">
                <el-input v-model="oldpwd" placeholder="请输入原密码" class="passwordinput"></el-input>
            </div>
        </div>
        <div class="row">
            <div class="lable">新密码</div>
            <div class="content">
                <el-input v-model="newpwd" placeholder="请输入新密码" class="passwordinput"></el-input>
            </div>
        </div>
        <div class="row">
            <div class="lable">确认新密码</div>
            <div class="content">
                <el-input v-model="okpwd" placeholder="请确认新密码" class="passwordinput"></el-input>
            </div>
        </div>
        <div class="row">
            <div class="lable"></div>
            <div class="content">
                <el-button type="warning" class="okbtn" @click="editok">确认修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Message } from 'element-ui';
import { editpwdapi } from '@/api/userinfo.js';



export default {
    data() {
        return {
            oldpwd: '',
            newpwd: '',
            okpwd: '',
        }
    },
    methods:{
        editok(){
            let id = this.$store.state.user.userinfo.id;
            if(!(this.oldpwd&&this.newpwd&&this.okpwd)){
                return Message({
                                showClose: true,
                                message: '请填写完整信息',
                                type: 'error'
                              });
            }
            if(!(this.newpwd == this.okpwd)){
                return Message({
                                showClose: true,
                                message: '两次密码不一致',
                                type: 'error'
                              });
            }
            editpwdapi({
                id,
                pswd: this.oldpwd,
                newPswd: this.okpwd,
            }).then((res) => {
                if(res.success == 2600){
                    this.$store.commit('user/set_loginout')
                    Message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    });
                    this.$router.replace({
                        path: '/login'
                    })
                }
                else{
                    Message({
                      showClose: true,
                      message: res.message,
                      type: 'error'
                    });
                }
            })
        },
    },
}
</script>

<style scoped>

    .passwordwrap{
        width: 100%;
    }

    .row{
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }

    .lable{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-right: 20px;
    }

    .content{
        width: 60%;
    }

    .passwordinput{
        width: 200px;
    }

    .row:nth-child(1){
        /* margin-top: 50px; */
        margin-top: 0px;
    }

</style>
