<template>
    <div class="userinfowrap">
        <headbox></headbox>
        <div class="usercontent">
            <div class="userinfoleft">
                <div class="headimg"></div>
                <div class="username">用户</div>
                <div :class="tabbar_active == 0 ? 'tabbar_active tabbar':'tabbar'" @click="tab(0)">
                    <span class="el-icon-s-custom icon"></span>
                    个人资料
                </div>
                <div :class="tabbar_active == 1 ? 'tabbar_active tabbar':'tabbar'" @click="tab(1)">
                    <span class="el-icon-key icon"></span>
                    修改密码
                </div>
                <div :class="tabbar_active == 2 ? 'tabbar_active tabbar':'tabbar'" @click="tab(2)">
                    <span class="el-icon-document-copy icon"></span>
                    进度查询
                </div>
                <div :class="tabbar_active == 3 ? 'tabbar_active tabbar':'tabbar'" @click="tab(3)">
                    <span class="el-icon-s-grid icon"></span>
                    更多服务
                </div>
                <div :class="tabbar_active == 4 ? 'tabbar_active tabbar':'tabbar'" @click="tab(4)">
                    <span class="el-icon-message-solid icon"></span>
                    系统通知
                </div>
            </div>
            <div class="userinforight">
                <div class="content_title">{{title_active}}</div>
                <div class="content_component">
                    <personal v-if="tabbar_active == 0"></personal>
                    <changpwd v-if="tabbar_active == 1"></changpwd>
                    <progressfind v-if="tabbar_active == 2"></progressfind>
                    <msg v-if="tabbar_active == 4"></msg>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import headbox from "@/components/head";
import foot from '@/components/foot'
import personal from '@/components/user/personal'
import changpwd from '@/components/user/changpwd'
import progressfind from '@/components/user/progress'
import msg from '@/components/user/message'

export default {
    components:{
        headbox ,
        foot ,
        personal ,
        changpwd ,
        progressfind ,
        msg ,
    },
    data() {
        return {
            tabbar_active: 0,
            title_active: '个人资料',
        }
    },
    methods:{
        tab(index){
            var tabarr = ['个人资料' , '修改密码' , '进度查询' , '更多服务' , '系统通知'];
            this.tabbar_active = index;
            this.title_active = tabarr[index];
        }
    }
}
</script>

<style scoped>

    .userinfowrap{
        font-size: 12px;
    }

    .usercontent{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 120px 0;
        background-image: url("../../assets/img/swiper2.jpg");
        background-size: 100% auto;
    }
    
    .userinfoleft{
        min-width: 185px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
    }

    .headimg{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-image: url("https://org.modao.cc/uploads4/images/5553/55534051/v2_qifjoj.png");
        background-size: 100% 100%;
        overflow: hidden;
        margin-top: 50px;
    }

    .username{
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .tabbar{
        cursor: pointer;
    }

    .icon{
        font-size: 18px;
        margin-right: 10px;
    }

    .tabbar{
        line-height: 50px;
        color: rgba(0, 0, 0, 0.3);
    }

    .tabbar_active{
        color: rgba(0, 0, 0, 1);
    }

    .userinforight{
        min-width: 1000px;
        height: 570px;
        background-color: #F0F0F0;
    }

    .content_title{
        margin: 0 50px;
        padding: 25px 0px;
        border-bottom: 1px solid #BBBBBB;
        font-size: 18px;
        font-weight: 600;
    }

    .content_component{
        width: 100%;
        height: 480px;
        box-sizing: border-box;
        padding: 0 50px;
        padding-top: 25px;
    }

</style>
