<template>
    <div class="personalwrap">
        <div class="row">
            <div class="lable">用户头像</div>
            <div class="content">
                <img class="head_img" />
            </div>
        </div>
        <div class="row">
            <div class="lable">性别</div>
            <div class="content">
                <el-radio v-model="sex" :label="1">男</el-radio>
                <el-radio v-model="sex" :label="2">女</el-radio>
            </div>
        </div>
        <div class="row">
            <div class="lable">用户昵称</div>
            <div class="content">
                <el-input v-model="username" placeholder="请输入用户昵称" class="personalinput"></el-input>
            </div>
        </div>
        <div class="row">
            <div class="lable">绑定手机</div>
            <div class="content">
                <el-input v-model="phone" placeholder="请输入绑定手机号" class="personalinput"></el-input>
            </div>
        </div>
        <div class="row">
            <div class="lable"></div>
            <div class="content">
                <el-button type="warning" class="okbtn" @click="edit">确认修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Message } from 'element-ui';
import { sexops_myval , sexops_serveval } from '@/utils/options.js';
import { userinfoapi , editinfoapi } from '@/api/userinfo.js';


export default {
    data() {
        return {
            sex: 1,
            username: '用户昵称',
            phone: '1234567890',
        }
    },
    methods:{
        edit(){
            if(this.username&&this.phone){
                let id = this.$store.state.user.userinfo.id;
                let form = {
                    id,
                    wxPhone: this.phone,
                    nickName: this.username,
                    gender: sexops_serveval[this.sex],
                }
                editinfoapi(form).then((res) => {
                    if(res.success == 2600){
                        this.$store.commit('user/set_userinfo',{
                          data: res.data.result
                        })
                        Message({
                                  showClose: true,
                                  message: '修改成功',
                                  type: 'success'
                                });
                    }
                    else{
                        Message({
                                  showClose: true,
                                  message: '修改失败',
                                  type: 'error'
                                });
                    }
                })
            }
            else{
                Message({
                      showClose: true,
                      message: '请填写完整信息',
                      type: 'error'
                    });
            }
        },
        init(){
            let id = this.$store.state.user.userinfo.id;
            userinfoapi({id : id}).then((res) => {
                if(res.success == 2600){
                    this.username = res.data.result.nickName;
                    this.sex = sexops_myval[res.data.result.gender];
                    this.phone = res.data.result.wxPhone;
                }
            })
        },
    },
    beforeMount(){
        this.init();
    },
}
</script>

<style scoped>

    .personalwrap{
        width: 100%;
    }

    .row{
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }

    .lable{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-right: 20px;
    }

    .content{
        width: 60%;
    }

    .head_img{
        width: 80px;
        height: 80px;
        margin-left: 40px;
    }

    .row:nth-child(1){
        /* margin-top: 50px; */
        margin-top: 0;
    }

    .personalinput{
        width: 200px;
    }

    .okbtn{
        /* margin-left: 40px; */
    }

</style>
