import request from '@/utils/request'

export function userinfoapi(data) {
  return request({
    url: '/v2/login/selectUser',
    method: 'POST',
    data: data
  })
}

export function editinfoapi(data) {
  return request({
    url: '/v2/login/updateUser',
    method: 'POST',
    data: data
  })
}

export function editpwdapi(data) {
  return request({
    url: '/v2/login/updatePawd',
    method: 'POST',
    data: data
  })
}
