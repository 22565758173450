
//0未知 1男 2女         serveval:myval
let sexops_myval = {
    '0': 0,
    '1': 1,
    '2': 2,
}
//                      myval:serveval
let sexops_serveval = {
    0: '0',
    1: '1',
    2: '2',
}

export {
    sexops_myval,
    sexops_serveval,
}